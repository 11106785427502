<template>
  <div class="auth-layout">
    <slot />
  </div>
</template>

<script>
export default {
    name : "AuthLayout",
};
</script>
